import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getPieFormatedDataByArray } from "../../../utils/util";
import { getRecords } from "../../../redux/reducers";

const StatusOfTreatment = () => {
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const { statusOfTreatment } = useSelector((state) => state.records?.current);
  const pieChartFormatData = getPieFormatedDataByArray(statusOfTreatment?.data?.items);

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetStatusOfTreatmentStats`,
        appendCurrentReducerKey: "statusOfTreatment",
      },
    });
  }, []);

  return (
    <div className="bg-white shadow-sm recieve-container">
      <Label>Status of Treatment</Label>
      <div className="d-flex justify-content-center">
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 260}
          height={isMobileView ? 240 : 260}
          series={pieChartFormatData?.values ?? "Loading..."}
          labels={pieChartFormatData?.labels ?? "Loading..."}
          position={"bottom"}
          colors={[
            "#CF5300",
            "#01CC9B",
          ]}
        />
      </div>
    </div>
  );
};

export default StatusOfTreatment;
