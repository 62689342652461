import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";

const TargetVsAchievedBenif = () => {
  const dispatch = useDispatch();

  const { targetVsAchievedBenif = {} } = useSelector(
    (state) => state.records?.current
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     getTargetVsAchievedBenif();
  //   }, 200);
  // }, []);

  const getTargetVsAchievedBenif = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetTargetVsAcheivedBeneficaries`,
        appendCurrentReducerKey: "targetVsAchievedBenif",
      },
    });
  };

  // Prepare the series and options for the bar chart
  const thematicAreas = {};
  targetVsAchievedBenif?.data?.items.forEach((item) => {
    const description = item.thematicAreaDescription;
    if (!thematicAreas[description]) {
      thematicAreas[description] = { achieved: 0, target: 0 };
    }
    thematicAreas[description].achieved += item.numberOfBeneficiaries;
    thematicAreas[description].target += item.target;
  });

  // Convert the thematicAreas object to arrays for the chart
  const series = [
    {
      name: "Achieved Beneficiaries",
      data: Object.values(thematicAreas).map((area) => area.achieved),
    },
    {
      name: "Target Beneficiaries",
      data: Object.values(thematicAreas).map((area) => area.target),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      legend: {
        show: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        download:true,
      },
    },
    legend: {
      show: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: Object.keys(thematicAreas), // Thematic area descriptions
    },
    yaxis: {
      title: {
        text: "Number of Beneficiaries",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " beneficiaries"; // Format tooltip
        },
      },
    },
    colors: ["#1DDBA9", "#0E7BB6"],
    grid: {
      show: false,
    },
  };

  return (
    <div className="fundsDonarDistribution">
      <Chart
        options={options ?? []}
        series={series ?? []}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default TargetVsAchievedBenif;
