import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: {},
  validations: {},
  lookups: {},
  reset: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormValues(state, action) {
      return { ...state, values: { ...state.values, ...action.payload } };
    },
    setValidations(state, action) {
      return { ...state, validations: action.payload };
    },
    setLookup: (state, action) => {
      return {
        ...state,
        lookups: { ...state.lookups, [action.payload.key]: action.payload.data },
      };
    },

    resetLookup: (state, action) => {
      return {
        ...state,
        lookups: { ...state.lookups, [action.payload.key]: [] },
      };
    },
    clearForm: (state, action) => {
      return {
        ...state,
        reset: true,
        values: {}
      };
    },
  },
});

export const { setValidations, clearForm, setLookup,resetLookup, setFormValues } = formSlice.actions;
// Action creators are generated for each case reducer function

export default formSlice.reducer;
