import React from "react";
import { Route, Routes, useLocation } from "react-router";

import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import "./AddContainer.scss";
import DashboardPage from "./../../pages/dashboard/DashboardPage";
import Projects from "./../../pages/projects/Projects";
import DefinitionManagement from "./../definition/DefinitionManagement";
import SearchFilterContainer from "./../search-filter/SearchFilterContainer";
import AddUpdateProject from "../../pages/projects/AddUpdateProject";
import Beneficiaries from "../../pages/beneficiaries/Beneficiaries";
import AddBeneficiaries from "../../pages/beneficiaries/AddBeneficiaries";
import SingleProject from "../../pages/projects/SingleProjects";
import MealProgressReports from "../../pages/meal-progress-report/MealProgressReports";
import MealProgressReport from "../../pages/meal-progress-report/MealProgressReport";
import AddMealProgressReport from "../../pages/meal-progress-report/AddMealProgressReport";
import Complaints from "../../pages/complaints/Complaints";
import Complaint from "../../pages/complaints/Complaint";
import AddUpdateComplaint from "../../pages/complaints/AddUpdateComplaint";
import Activity from "../../pages/activity/Activity";
import Logframe from "../../pages/logframe/Logframe";
import SpecificObjective from "../../pages/specificobjective/SpecificObjective";
import usePageMeta from "../../hooks/usePageMeta";
import MonitoringVisitReports from "../../pages/monitoring-visit-report/MonitoringVisitReports";
import MonitoringVisitReport from "../../pages/monitoring-visit-report/MonitoringVisitReport";
import AddUpdateMonitoringVisitReport from "../../pages/monitoring-visit-report/AddUpdateMonitoringVisitReport";
import ProjectAlbums from "../../pages/projects/ProjectAlbums";
import ComplaintDashboard from "../../pages/complaints/ComplaintDashboard";
import ProjectAlbum from "../../pages/projects/ProjectAlbum";
import AddActivity from "../activities/AddActivity";
import UserDetailPage from "../../pages/userAccount.js/UserDetailPage";
import UserUpdatePage from "../../pages/userAccount.js/UserUpdatePage";
import UserManagements from "../../pages/user-management/UserManagements";
import UserManagement from "../../pages/user-management/UserManagement";
import AddUpdateUserManagement from "../../pages/user-management/AddUpdateUserManagement";
import NotificationPage from "../../pages/notification/NotificationPage";
import ChangePassword from "../../pages/userAccount.js/ChangePassword";
import { useSelector } from "react-redux";
import { canUserAccess, canView } from "../../utils/util";
import { USER_ROLE } from "../../Constants";

const AppContainer = () => {
  // const { setMeta } = usePageMeta();
  const location = useLocation();
  const roles = useSelector((state) => state.setting?.user?.userDetail?.roles);
  const userRoles = roles && roles.map(({ name }) => name);

  React.useEffect(() => {
    // setMeta();
  }, [location]);

  return (
    <div className="d-flex flex-wrap">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="px-4 main-content">
        {roles && <Header />}
        <Routes>
          <Route path="/user/*">
            <Route path="Detail" element={<UserDetailPage />} />
            <Route path=":id" element={<UserUpdatePage />} />
            <Route path="changePassword" element={<ChangePassword />} />
          </Route>

          <Route
            path="/*"
            element={
              canView([USER_ROLE.ADMIN, USER_ROLE.MANAGEMENT ], userRoles) ? (
                <DashboardPage />
              ) : (
                <Projects />
              )
            }
          />
          <Route path="/projects/*">
            <Route path="" element={<Projects />} />
            {canView([USER_ROLE.ADMIN,USER_ROLE.SUPER_ADMIN,USER_ROLE.BASE_PROJECT_MANAGER,USER_ROLE.BASE_COUNTRY_MANAGER], userRoles) && (
              <Route path="add" element={<AddUpdateProject />} />
            )}
            <Route path="update/:id" element={<AddUpdateProject />} />
            <Route path=":id" element={<SingleProject />} />

            <Route path="albums/:id" element={<ProjectAlbums />} />

            <Route
              path="specific-objective/:id"
              element={<SpecificObjective />}
            />
            <Route path="logframe/:id" element={<Logframe />} />
            <Route
              path="albums/:id/album/:albumId"
              element={<ProjectAlbum />}
            />
          </Route>
          <Route path="/definitions/*" element={<DefinitionManagement />} />
          <Route path="/search-filter/*" element={<SearchFilterContainer />} />
          <Route path="/beneficiaries/*">
            <Route path="" element={<Beneficiaries />} />
            <Route path=":id" element={<Beneficiaries />} />
            <Route path="add" element={<AddBeneficiaries />} />
            <Route path="update/:id" element={<AddBeneficiaries />} />
          </Route>
          {canView([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN], userRoles) && (
            <>
              <Route path="/meal-progress-report/*">
                <Route path="" element={<MealProgressReports />} />
                <Route path=":id" element={<MealProgressReport />} />
                <Route path="add" element={<AddMealProgressReport />} />
                <Route path="update/:id" element={<AddMealProgressReport />} />
              </Route>
              <Route path="/monitoring-visit-report/*">
                <Route path="" element={<MonitoringVisitReports />} />
                <Route path=":id" element={<MonitoringVisitReport />} />
                <Route
                  path="add"
                  element={<AddUpdateMonitoringVisitReport />}
                />
                <Route
                  path="update/:id"
                  element={<AddUpdateMonitoringVisitReport />}
                />
              </Route>
            </>
          )}

          <Route path="/complaints/*">
            <Route path="" element={<Complaints />} />
            <Route path="dashboard" element={<ComplaintDashboard />} />
            <Route path="project/:id" element={<Complaints />} />
            <Route path=":id" element={<Complaint />} />
            {canView([USER_ROLE.ADMIN,USER_ROLE.MANAGEMENT, USER_ROLE.OFFICER], userRoles) && (
              <Route path="add" element={<AddUpdateComplaint />} />
            )}
            <Route path="update/:id" element={<AddUpdateComplaint />} />
          </Route>

          {canView([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN], userRoles) && (
            <Route path="/UserManagement/*">
              <Route path="" element={<UserManagements />} />
              <Route path="project/:id" element={<UserManagements />} />
              <Route path=":id" element={<UserManagement />} />
              <Route path="add" element={<AddUpdateUserManagement />} />
              <Route path="update/:id" element={<AddUpdateUserManagement />} />
            </Route>
          )}
          <Route path="/activity/*">
            <Route path="" element={<Activity />} />
            <Route path=":id" element={<Activity />} />
            <Route path="add" element={<AddActivity />} />
          </Route>
          <Route path="/notifications/*">
            <Route path="" element={<NotificationPage />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default AppContainer;
