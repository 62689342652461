import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getPieFormatedData, getPieFormatedDataByArray } from "../../../utils/util";
import { getRecords } from "../../../redux/reducers";

const LevelOfSatisfaction = () => {
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const { satisfactionStats } = useSelector((state) => state.records?.current);
  const pieChartFormatData = getPieFormatedData(satisfactionStats?.data);
  
  //to remove total label and value
  pieChartFormatData?.labels?.pop();
  pieChartFormatData?.values?.pop();  


  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetSatisfectionLevelStats`,
        appendCurrentReducerKey: "satisfactionStats",
      },
    });
  }, []);

  return (
    <div className="bg-white shadow-sm recieve-container">
      <Label>LevelOfSatisfaction</Label>
      <div className="d-flex justify-content-center">
      {/* TODO update with API data once API is ready */}
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 260}
          height={isMobileView ? 240 : 260}
          series={pieChartFormatData.values ?? "Loading..."}
          labels={pieChartFormatData.labels ?? "Loading..."}
          position={"bottom"}
        
        />
      </div>
    </div>
  );
};

export default LevelOfSatisfaction;
