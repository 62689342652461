import smartIndicatorStatus from "../../assets/enums/smartIndicatorStatus";

const SmartIndicatorLabels = ({ items, ...otherProps }) => {
    if (!Array.isArray(items))
        return <></>;

    function getStatusLabel(status) {
        switch (status) {
            case smartIndicatorStatus.Completed:
                return 'success';
            case smartIndicatorStatus.InProgress:
                return 'warning';
            case smartIndicatorStatus.Pending:
                return 'light';
            default:
                return 'light'
        }
    }
    return <>
        {items && items.map(({ status = 'light' }, index) => (<span key={index} className={`smart-indicator-label bg-${getStatusLabel(status)}`}></span>))}
        {/* {items && items.map(({ status = 'light' }, index) => (<span key={index} className="">{status}</span>))} */}

    </>;
}

export default SmartIndicatorLabels;