import React, { useEffect, useState } from "react";
import { RecordCircle } from "react-bootstrap-icons";
import "./Indicators.scss";
import Chart from "react-apexcharts";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import { truncateText } from "../../../utils/util";

const Indicator = () => {
  const dispatch = useDispatch();

  const { inidicatorsData = {} } = useSelector(
    (state) => state.records?.current
  );

  useEffect(() => {
    setTimeout(() => {
      getIndicatorsData();
    }, 200);
  }, []);

  const getIndicatorsData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetSmartIndicatorPerformance`,
        appendCurrentReducerKey: "inidicatorsData",
      },
    });
  };

  // Prepare the series and options for the bar chart
  const indicators = {};
  (inidicatorsData?.data?.items ?? []).forEach((item) => {
    const description = truncateText(item?.smartIndicatorName, 20);
    if (!indicators[description]) {
      indicators[description] = { achieved: 0, target: 0 };
    }
    indicators[description].achieved += parseFloat(item.totalAchieved);
    indicators[description].target += parseFloat(item.totalTarget);
  });

  // Convert the thematicAreas object to arrays for the chart
  const series = [
    {
      name: "Achieved",
      data: Object.values(indicators).map((area) => area?.achieved),
    },
    {
      name: "Target",
      data: Object.values(indicators).map((area) => area?.target),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      legend: {
        show: true,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: Object.keys(indicators), // Thematic area descriptions
    },
    yaxis: {
      title: {
        text: "Number of Beneficiaries",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " beneficiaries"; // Format tooltip
        },
      },
    },
    colors: ["#1DDBA9", "#0E7BB6"],
    grid: {
      show: false,
    },
  };

  return (
    <div className="bg-white shadow-sm h-100 fundsDonarDistribution">
      <Label>Smart Indicators</Label>
      <Chart
        options={options ?? []}
        series={series ?? []}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default Indicator;
