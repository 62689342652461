import React from "react";
import Indicator from "./Indicator";
import "./Indicators.scss";

const IndicatorMainTab = () => {
  return (
    <div class="container-fluid p-0 my-4">
      <div class="row px-0 g-3 row-container">
        <div class="col-12 col-indicator">
          <Indicator />
        </div>
      </div>
    </div>
  );
};

export default IndicatorMainTab;
