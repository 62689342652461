export const exportFields = [
  {
    category: "exportReport",
    label: "Add Year",
    type: "number",
    inForm: true,
    name: "Year",
    placeholder: "Add Year",
    wrapperClass: "col-12",
    labelAttribute: "label",
    valueAttribute: "value",
  },
  // {
  //   category: "exportReport",
  //   label: "Select Report Type*",
  //   placeholder: "Select Report Type",
  //   name: "reportType",
  //   inputType: "async-dropdown",
  //   searchAttrib: "name",
  //   id: "reportType",
  //   labelAttribute: "name",
  //   valueAttribute: "id",
  //   required: true,
  //   onChangeMethod: "asyncdropdown",
  //   options: [
  //     {
  //       label: "Project Wise Beneficiaries",
  //       value: "PWB",
  //     },
  //     {
  //       label: "Mission Wise Beneficiaries",
  //       value: "MWB",
  //     },
  //   ],
  //   inForm: true,
  //   wrapperClass: "col-md-12",
  //   isMulti: false,
  // },
  {
    label: "Select Format*",
    placeholder: "Select Report Format",
    name: "reportFormate",
    id: "reportFormate ",
    required: true,
    inputType: "async-dropdown",
    options: [
      {
        label: "PDF",
        value: "PDF",
      },
      // {
      //   label: "Excel",
      //   value: "Excel",
      // },
      // {
      //   label: "Word Document",
      //   value: "Word",
      // },
    ],
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: false,
    wrapperClass: "col-md-12",
    isMulti: false,
    inResult: false,
  },

  {
    inputType: "button",
    buttonType: "submit",
    children: "Get Report",
    name: "getReport",
    inSearch: true,
    wrapperClass: "btn-wrapper  ml-auto mt-2",
  },
];
