import React from "react";
import DataTable from "react-data-table-component";
import "./table.scss";

const Table = ({ columns = [], data = [], styles = {}, disablePagination, canExport = false, ...otherProps }) => {
  function downloadXLS() {
    const XLSX = window.XLSX;
    const ws = XLSX.utils.json_to_sheet([...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "report");
    XLSX.writeFile(wb, "records.xlsx");
  }
  return (
    <>

      <DataTable
        columns={columns}
        data={data}
        customStyles={{ ...customStyles, ...styles }}
        pagination ={!disablePagination}
        dense
        {...otherProps}
      />
      {canExport && <button onClick={() => downloadXLS()} className="btn btn-info float-right mt-5 w-15 ml-auto">Export to Excel</button>}
    </>
  );
};

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#2275b6",
      color: "#fff",
      paddingBottom: 16,
      paddingTop: 16,
      fontSize: 14,
    },
  },
  cells: {
    style: {
      padding: 16,
      fontSize: 15,
      maxWidth: 100,
    },
  },
};

export default Table;
