import { Link } from "react-router-dom";
import TableTitle from "../../components/table-title/TableTitle";
import { getFormatedDate } from "../../Utility";
import ToolTip from "../../components/tooltip/Tooltip";
import { truncateText } from "../../utils/util";

const MonitoringVisitColumns = [
  // {
  //   name: "Project Title",
  //   selector: (row) => (
  //     <Link to={`${row?.id}`}>
  //       <TableTitle title={row?.project?.name} />
  //     </Link>
  //   ),
  // },
  {
    name: "Date Of Monitoring",
    selector: (row) => getFormatedDate(row?.dateOfMonitoring),
  },
  // {
  //   name: "Start Date",
  //   selector: (row) => getFormatedDate(row?.projectStartDate),
  // },
  // {
  //   name: "End Date",
  //   selector: (row) => getFormatedDate(row?.projectEndDate),
  // },
  {
    name: "Reporting Person",
    selector: (row) => row?.reportingPerson?.name,
  },
  {
    name: "Debrief User",
    selector: (row) => row?.debriefedUser?.name,
  },
  {
    name: "Date of Debrief",
    selector: (row) => getFormatedDate(row?.dateOfDeBrief),
  },
  {
    name: "Location Of Visit",
    selector: (row) => <ToolTip label={`${row?.locationOfVisit}`}>{truncateText(row?.locationOfVisit ?? "",20)}</ToolTip>,
  },
];

export default MonitoringVisitColumns;
