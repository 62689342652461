import * as Icon from "react-bootstrap-icons";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getRecords } from "../../redux/reducers";

const SearchList = ({
  url,
  params,
  searchFieldName = "Name",
  placeholder = "Search",
  appendCurrentReducerKey = "",
  className = " w-50",
}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleSearch = (value) => {
    if (value === null) return;
    if (appendCurrentReducerKey !== "") {
      dispatch({
        type: getRecords.type,
        payload: {
          url,
          params: {
            [searchFieldName]: value,
            ...params,
            
          },
          appendCurrentReducerKey: appendCurrentReducerKey,
        },
      });
    } else {
      dispatch({
        type: getRecords.type,
        payload: {
          url,
          params: { [searchFieldName]: value, ...params },
        },
      });
    }
  };

  return (
    <div className={`left d-flex align-items-center p-2 py-1 px-2 border  rounded shadow-sm  ${className}`}>
      <input
        type="text"
        className="t-input w-100 text-primary"
        placeholder={placeholder}
        onChange={(event) => setQuery(event?.target?.value)}
      />
      <span className="ml-3 text-primary">
        <Icon.Search />
      </span>
    </div>
  );
};

export default SearchList;
