import React from "react";
import ReactApexChart from "react-apexcharts";
const SimplePieChart = ({
  attributes,
  labels = [],
  series = [],
  position,
  ...elementProps
}) => {
  let options = {
    labels: labels,
    colors: elementProps?.colors,
    legend: {
      position,
      // showForSingleSeries: true,
      // formatter: function (seriesName, opts) {
      //   return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
      // },
      ...elementProps?.legendOptions,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              showAlways: true,
              //formatter: () => '343',
              fontSize: 10,
              color: "#f90000",
            },
          },
        },
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val, opt, w) {
        // return (
        //   opt.w.config.series[opt.seriesIndex] +
        //   " " +
        //   opt.w.config.labels[opt.seriesIndex]
        // );
        let series = opt.w.config.series;
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[opt.seriesIndex];
        return Math.round((selected / total) * 100) + "%";
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[seriesIndex];
        // return Math.round((selected / total) * 100) + "%";
        return w.config.labels[seriesIndex];
      },
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
  };

  return (
    <ReactApexChart
      type="pie"
      width={300}
      height={300}
      series={series}
      options={options}
      {...elementProps}
    />
  );
};

export default SimplePieChart;
