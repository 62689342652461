import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import "./AgeandGenderView.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import { getPieFormatedData } from "../../../utils/util";

const AgeandGenderView = () => {
  const isMobileView = useMediaQuery("(max-width: 425px)");
  const isTabView = useMediaQuery("(min-width: ) and (max-width: 768px)");
  const dispatch = useDispatch();
  const {
    gendersCount = { data: {} },
    ageRangeGendersCount = { data: { items: [] } },
    pwdData = { data: { items: [] } },
  } = useSelector((state) => state.records.current || {});

  // useEffect(() => {
  //   setTimeout(() => {
  //     getBeneficiaryCountByGenders();
  //     getAgecountData();
  //     getPwdData();
  //   }, 200);
  // }, []);

  // const getBeneficiaryCountByGenders = () => {
  //   dispatch({
  //     type: getRecords.type,
  //     payload: {
  //       url: `/Dashboard/GetAgeRangeGroupCount`,
  //       appendCurrentReducerKey: "ageRangeGendersCount",
  //     },
  //   });
  // };

  // const getAgecountData = () => {
  //   dispatch({
  //     type: getRecords.type,
  //     payload: {
  //       url: `/Dashboard/GetBeneficiaryCountByGenders`,
  //       appendCurrentReducerKey: "gendersCount",
  //     },
  //   });
  // };

  // const getPwdData = () => {
  //   dispatch({
  //     type: getRecords.type,
  //     payload: {
  //       url: `/Dashboard/ProjectWisePWD`,
  //       appendCurrentReducerKey: "pwdData",
  //     },
  //   });
  // };

  let totalFemales = 0;
  let totalMales = 0;

  // Iterate through each project and sum the counts
  pwdData.data?.items &&
    pwdData.data?.items?.forEach((project) => {
      totalFemales += project.pwdFemaleCount;
      totalMales += project.pwdMaleCount;
    });

  // Age Range Methods
  const getRangeGenderLabels = () => {
    const labels = [];
    ageRangeGendersCount.data.items.forEach((item) => {
      labels.push(item?.ageRangeName || "Unknown");
    });
    return labels;
  };
  const getRangeGenderData = () => {
    const data = [];
    ageRangeGendersCount.data.items.forEach((item) => {
      data.push(item?.ageRangeCount || 0);
    });
    return data;
  };

  const ageCountData = {
    values: getRangeGenderData(),
    labels: getRangeGenderLabels(),
  };

  const genderCountData = {
    values: [
      gendersCount.data.male || 0,
      gendersCount.data.female || 0,
      gendersCount.data.transgender || 0,
    ],
    labels: ["Male", "Female", "Others"],
  };

  const pwdChartData = {
    values: [totalMales, totalFemales],
    labels: ["Male", "Female"],
  };

  return (
    <div className="bg-white charts-view h-100 shadow-sm justify-content-evenly">
      {ageCountData ? (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <div className="px-4">
            <Label>Age</Label>
          </div>
          <SimplePieChart
            type="donut"
            width={isMobileView ? 140 : 240}
            height={isMobileView ? 240 : 260}
            series={ageCountData.values}
            labels={ageCountData.labels}
            position={"bottom"}
            
          />
        </div>
      ) : (
        "Loading..."
      )}
      <div className="seprator"></div>
      <div className="d-flex flex-column justify-content-center align-item-center">
        <div className="px-4">
          <Label>Gender</Label>
        </div>
        {genderCountData && (
          <SimplePieChart
            type="donut"
            width={isMobileView ? 140 : 240}
            height={isMobileView ? 240 : 260}
            series={genderCountData.values}
            labels={genderCountData.labels}
            position={"bottom"}
            colors={["#1470A2", "#C7233B", "#FCC028"]}
          />
        )}
      </div>
      <div className="seprator"></div>

      <div className="d-flex flex-column justify-content-center align-item-center">
        <div className="px-4">
          <Label>Disability</Label>
        </div>
        {pwdChartData && (
          <SimplePieChart
            type="donut"
            width={isMobileView ? 140 : 240}
            height={isMobileView ? 240 : 260}
            series={pwdChartData?.values}
            labels={pwdChartData?.labels}
            position={"bottom"}
            colors={["#1470A2", "#C7233B"]}
          />
        )}
      </div>
    </div>
  );
};

export default AgeandGenderView;
