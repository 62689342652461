import Badge from "../../components/badge/Badge";
import ColumnLink from "../../components/column-link/ColumnLink";
import { getFormatedDate } from "../../Utility";

const ProjectColumns = [
  {
    name: "Project Name",
    selector: (row) => (
      <>
        {/* <Link to={`${row.id}`} className="text-capitalize">
          {row.title}
        </Link> */}
        <ColumnLink className="font-weight-bold" to={`/projects/${row.id}`}>{row?.title}</ColumnLink>
      </>
    ),
    // wrap: true,
    width: "450px"
  },
  // {
  //   name: "Budget",
  //   selector: (row) => row.totalCostInEUR
  // },
  // {
  //   name: "Status",
  //   selector: (row) => (
  //     <label className={row?.isActive ? "text-success" : "text-danger"}>
  //       {row?.isActive ? "Opened" : "Closed"}
  //     </label>
  //   )
  // },
  {
    name: "Project SDGs",
    selector: (row) => (
      <>
        {row.sdGs &&
          row.sdGs.map(({ name = "" }, index) => (
            <div
              className="m-1 text-left text-success font-weight-bold"
              style={{ lineHeight: "14px", fontSize: "12px", padding: 4 }}
              key={index}
            >
              {name}
            </div>
          ))}
      </>
    ),
    wrap: true,
    width: "250px"
  },
  {
    name: "Target Beneficiaries",
    selector: (row) => row?.finalBeneficiaries,
  },
  {
    name: "Project Status",
    selector: (row) => row?.status,
  },
  {
    name: "Start Date",
    selector: (row) => getFormatedDate(row?.startDate),
  },
  {
    name: "End Date",
    selector: (row) => getFormatedDate(row?.endDate),
  },
];

export default ProjectColumns;
