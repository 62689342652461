import BeneficiaryColumns from "../columns/BeneficiaryColumns";
import SubActivityBeneficiaryColumns from "../columns/SubActivityBeneficiaryColumns";

export const subActivityAchievementFields = [
  {
    category: "non-benef",
    label: "Numbers Achieved",
    placeholder: "Enter Numbers",
    type: "number",
    required: true,
    inForm: true,
    name: "NumbersAchieved",
    wrapperClass: "col-6",
  },
  {
    category: "benef",
    label: "Beneficiaries",
    name: "BeneficiaryIds",
    id: "BeneficiaryIds",
    inputType: "SelectableTable",
    columns: SubActivityBeneficiaryColumns,
    // url: "/ProjectBeneficiary/GetSubActivityAchievementBeneficiaries?ProjectId=",
    // url:"/SubActivityAchievementForBeneficiary/GetBeneficiaries?DisablePagination=true&SubActivityId=",
    url: "/SubActivityAchievementForBeneficiary/GetSelectedBeneficiaries?DisablePagination=true&SubActivityId=",
    valueAttribute: "id",
    searchAttribute: "Name",
    onChangeMethod: "onTableSelection",
    inForm: true,
    customStyles: { maxHeight: 450 },
    wrapperClass: "col-12",
  },
  {
    category: "non-benef",
    label: "Date",
    inputType: "date",
    inForm: true,
    required: true,
    name: "Date",
    wrapperClass: "col-6",
  },
  {
    category: "non-benef",

    label: "Attachments",
    inputType: "file",
    inForm: true,
    name: "Attachments",
    wrapperClass: "col-6",
  },
  {
    category: "non-benef",

    label: "Comments",
    placeholder: "Enter Comments",
    inputType: "textarea",
    inForm: true,
    name: "Comments",
    wrapperClass: "col-6",
  },
  {
    category: "non-benef",

    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    category: "non-benef",

    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "mr-3 btn-wrapper position-right",
  },
];
