import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import { getPieFormatedData, getPieFormatedDataByArray } from "../../../utils/util";

const RecieveMode = () => {
  const dispatch = useDispatch();
  const { complaintRecievingMode } = useSelector((state) => state.records?.current);
  const pieChartFormatData = getPieFormatedDataByArray(complaintRecievingMode?.data?.items);

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetReceivingModeStats`,
        appendCurrentReducerKey: "complaintRecievingMode",
      },
    });

  }, [])

  const isMobileView = useMediaQuery("(max-width: 768px)");

  return (
    <div className="bg-white shadow-sm recieve-container">
      <Label>Recieve Mode</Label>
      <div className="d-flex justify-content-center">
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 260}
          height={isMobileView ? 240 : 260}
          series={pieChartFormatData?.values ?? "Loading..."}
          labels={pieChartFormatData?.labels ?? "Loading..."}
          position={"bottom"}
          colors={["#09909F", "#01CC9B", "#CF5300", "#0075B6", "#5059AB"]}
        />
      </div>
    </div>
  );
};

export default RecieveMode;
