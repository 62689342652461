import SmartIndicatorLabels from "../../components/smart-indicator/SmartIndicatorLabels";
import ColumnInnerList from "../../components/Column/ColumnInnerList";
import { Link } from "react-router-dom";

const LogframeColumns = [
  {
    name: "Intervention Logic",
    wrap: true,
    selector: (row) => <div className="py-3"><Link to={`/projects/specific-objective/${row.id}`}><label className="text-primary text-uppercase mb-0 cursor-pointer">{row.interventionLogic}</label></Link></div>,
  },
  {
    name: "Smart Indicator",
    wrap: true,
    selector: (row) => <SmartIndicatorLabels items={row?.smartIndicators} />,
  },
  {
    name: "Means of Verification",
    wrap: true,
    selector: (row) => <ColumnInnerList items={row?.meansOfVerifications && row?.meansOfVerifications.map(({name})=>name)} />,
  },
  {
    name: "Assumptions/Risks",
    wrap: true,
    selector: (row) => <ColumnInnerList items={row?.assumptionsOrRisks && row?.assumptionsOrRisks.map(({name})=>name)} />,
  },
];

export default LogframeColumns;
