import ToolTip from "../../components/tooltip/Tooltip";
import { COMPLAINT_STATUS_TYPE } from "../../Constants";
import { getFormatedDate } from "../../Utility";
import { truncateText } from "../../utils/util";

const ComplaintFeedbackColumns = [
  {
    name: "Designated Person",
    selector: (row) => row?.designatedPerson?.name,
    // maxWidth: 150
  },
  {
    name: "Communicaton Person",
    selector: (row) => row?.communicatingPerson?.name,
    // maxWidth: 150
  },
  {
    name: "Complaint Status",
    selector: (row) => (
      <>
        <span
          className={
            row.statusOfComplaint == COMPLAINT_STATUS_TYPE.CLOSED
              ? "text-success"
              : "text-warning"
          }
        >
          {row?.statusOfComplaint}
        </span>
      </>
    ),
    // maxWidth: 150,
  },
  {
    name: "Resolving Date",
    selector: (row) => getFormatedDate(row?.complaintResolvingDate),
    // maxWidth: 150,
  },
  {
    name: "Days to Resolving",
    selector: (row) => row?.noOfDaysToResolveComplaint,
    // maxWidth: 150,
  },
  {
    name: "Action Taken",
    selector: (row) => <ToolTip label={`${row?.actionTaken}`}>{truncateText(row?.actionTaken ?? "",20)}</ToolTip>,
    // maxWidth: 150,
  },
  {
    name: "Outcome",
    selector: (row) => <ToolTip label={`${row?.outcome}`}>{truncateText(row?.outcome ?? "",20)}</ToolTip>,
    // maxWidth: 150,
  },
];

export default ComplaintFeedbackColumns;
