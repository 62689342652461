import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Form from '../../components/form/Form';
import { resetData, formatRecords, setStepper } from '../../redux/reducers';
import Loader from '../loader/loader';

const ProjectDetailForm = ({ canUpdate = false, projectId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formFields } = useSelector((state) => state.fields);
    const { loading, created,creating, response = {}, data = {} } = useSelector((state) => state.records);
    const { stepper } = useSelector((state) => state.setting);
    const { currentStep } = stepper;

    //get form fields
    useEffect(() => {
        dispatch({ type: 'fields/fetchLayout', payload: '/json/project-fields' })
    }, [])

    //action on project create
    useEffect(() => {
        if (created && response?.data) {
            navigate(`/projects/update/${response?.data}`)
        }
        return () => {
            if (created)
                dispatch({ type: resetData.type })
        }
    }, [created])

    useEffect(() => {
        if (!creating && created) {
            dispatch({ type: setStepper.type, payload: { currentStep: (currentStep + 1) } })
        }
    }, [creating, created])

    //update project actions
    useEffect(() => {
        if (projectId)
            dispatch({ type: "records/getRecords", payload: { url: `/project/${projectId}` } })
        return () => {
            dispatch({ type: resetData.type })
            dispatch({ type: 'form/clearForm' })
        }
    }, [projectId])

    //mask data on single project data retrieval
    useEffect(() => {
        if (data && stepper?.currentStep == 0) {
            dispatch({ type: formatRecords.type, payload: { ...data } })
        }
    }, [data, stepper?.currentStep])

    function getFieldsByCategory(fields = [], key) {
        return fields.filter(({ category = '' }) => category == key)
    }

    return (
        <>
            <Loader loading={loading} />
            <Form
                formData={getFieldsByCategory(formFields, 'project-detail')}
                // onSubmit={(params) => postData(params)}
                withValidation={false}
                formValidation={[]}
                extraInputClass=""
            />
        </>
    )
}
export default ProjectDetailForm;