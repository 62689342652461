import { put } from "redux-saga/effects";
import axios from "./api-client";
import { setRecords, setErrors, setUserMessages, clearForm } from "../reducers";
import { baseUrl } from "..";
import { formatByHeader, formatPostFields } from "./sagas.helper";

export function* postRecordAsync(action) {
  const { url, headers = {}, ...payload } = action.payload;
  const fullUrl = baseUrl + '/api' + url;
  if (url) {
    try {

      let formatedData = formatPostFields(payload);
      formatedData = formatByHeader(formatedData, headers);

      const data = yield axios
        .post(fullUrl, formatedData, { headers })
        .then(({ data }) => data);
      yield put({
        type: setRecords.type,
        payload: { response: data, created: true },
      });
      yield put({
        type: setUserMessages.type,
        payload: {
          ...action,
          response: data,
          message: "Record Created Successfully!",
          created: true,
        },
      });

      yield put({
        type: clearForm.type,
      });
    } catch (e) {
      yield put({
        type: setUserMessages.type,
        payload: { ...action.payload, ...e },
      });
      yield put({
        type: setErrors.type,
        payload: { errorIn: action.type, message: e.message },
      });
      yield put({
        type: "records/setErrors",
        payload: { errorIn: action.type, message: e.message },
      });
    }
  } else {
    yield put({
      type: setErrors.type,
      payload: { errorIn: action.type, message: "No url specified!" },
    });
    yield put({
      type: "records/setErrors",
      payload: {
        errorIn: action.type,
        message: "No url specified for record!",
      },
    });
  }
}
