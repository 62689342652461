import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import MAP_ICON from "./../../assets/images/map-icon.png";
import "./Projects.scss";
import LightBoxGallery from "../lightbox-gallery/LightBoxGallery";
import { baseUrl } from "../../redux";

const ProjectAlbum = () => {
  const dispatch = useDispatch();
  let { id, albumId, selectedImage = null } = useParams();
  const { images } = useSelector((state) => state.records?.current);

    const updatedImagesUrl = images?.data?.attachments?.filter(({ url }) => url)
    .map(({ url }) => ({
      original: `${baseUrl}${url}`,
      thumbnail: `${baseUrl}${url}`,
    }));



  useEffect(() => {
    dispatch({ type: "records/getRecords", payload: { url: `/Gallery/${albumId}`, appendCurrentReducerKey: "images" } })
  }, []);

  return <LightBoxGallery imageList={updatedImagesUrl} selectedImage={selectedImage} />;
};

export default ProjectAlbum;
