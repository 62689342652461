import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  current: {},
  loading: false,
  creating: false,
  created: false,
};

export const recordSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    getRecords(state, action) {
      const isCurrentData = action.payload?.appendCurrentReducerKey;
      return { ...state, loading: true, data: isCurrentData ? state.data : [] };
    },
    setRecords(state, action) {
      const recordPayload = action.payload?.current
        ? {
            ...action.payload,
            current: { ...state.current, ...action.payload.current },
          }
        : { ...action.payload };
      return {
        ...state,
        loading: false,
        ...recordPayload,
        created: false,
        error: {},
      };
    },
    createRecord(state, action) {
      return { ...state, creating: true, loading: true };
    },
    putRecord(state, action) {
      return { ...state, creating: true };
    },
    setErrors(state, action) {
      return {
        ...state,
        creating: false,
        created: false,
        loading: false,
        error: action.payload,
      };
    },
    resetData(state, action) {
      return {
        ...state,
        data: [],
        created: false,
        creating: false,
        loading: false,
        response: {},
      };
    },
    formatRecords() {},
    onRecordSuccess(state, action) {
      return { ...state, created: true, loading: false, creating: false };
    },
    deleteRecord(state, action) {
     
    },
  },
});

export const {
  setRecords,
  getRecords,
  createRecord,
  putRecord,
  setErrors,
  resetData,
  formatRecords,
  onRecordSuccess,
  deleteRecord,
} = recordSlice.actions;

export default recordSlice.reducer;
