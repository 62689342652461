import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../components/loader/loader";
import beneficiaryFields from "../../assets/fields/beneficiariesFields";
import Form from "../../components/form/Form";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import { getFieldsByCategory } from "../../utils/util";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";

const AddBeneficiaries = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();
  const { loading, created, data } = useSelector((state) => state.records);
  const { values, validations } = useSelector((state) => state.form);
  const formValues = values;

  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    if (created) {
      navigate("/beneficiaries");
    }
  }, [created]);

  

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/beneficiary/${id}` },
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  const addRecord = () => {
    dispatch({
      type: createRecord.type,
      payload: { ...formValues, url: "/Beneficiary" },
    });
  };

  function updateRecord() {
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: "/Beneficiary" },
    });
  }

  return (
    <div className="mb-4">
      <Loader loading={loading} />
      <FormStepper
        initialStep={0}
        onSubmit={() => (canUpdate ? updateRecord() : addRecord())}
      >
        <Step key="beneficiaryDetail" title="Beneficiary Details">
          <Form
            formData={getFieldsByCategory(beneficiaryFields, "details")}
            withValidation={true}
            formValidation={validations}
            extraInputClass=""
          />
        </Step>
        <Step key="project-address" title="Project and Address">
          <div className="col-12">
            <Form
              formData={getFieldsByCategory(beneficiaryFields, "address")}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
        </Step>
      </FormStepper>
    </div>
  );
};

export default AddBeneficiaries;
