import ToolTip from "../../components/tooltip/Tooltip";
import { maskCNIC } from "../../utils/util";

const SubActivityBeneficiaryColumns = [

    {
      name: "Beneficiary ID",
      selector: (row) => row?.beneficiaryId,
    },
    {
      name: "Beneficiary Name",
      selector: (row) => row?.beneficiaryName,
    },
    {
      name: "Beneficiary CNIC",
      selector: (row) => <ToolTip label={`${row?.cnic}`}>{maskCNIC(row?.cnic ?? "")}</ToolTip>,
    },
   
  ];
  
  export default SubActivityBeneficiaryColumns;
  