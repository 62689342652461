import React from "react";
import Chart from "react-apexcharts";

const DonutChart = ({
  attributes,
  labels = [],
  series = [],
  position = "bottom",
  ...elementProps
}) => {
  let options = {
    labels: labels,
    legend: {
      position,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              //formatter: () => '343',
              fontSize: 10,
              color: "#f90000",
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[seriesIndex];
        return Math.round((selected / total) * 100) + "%";
      },
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
  };

  return (
    <Chart
      type="donut"
      width={300}
      height={300}
      series={series}
      options={options}
      {...elementProps}
    />
  );
};

export default DonutChart;
