import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import {
  clearForm,
  createRecord,
  formatRecords,
  putRecord,
} from "../../redux/reducers";
import * as Icon from "react-bootstrap-icons";
import Form from "../form/Form";
import Loader from "../loader/loader";
import { useParams } from "react-router-dom";
import DeleteRow from "../../pages/DeleteRow";
import Table from "../table/Table";
import ToolTip from "../tooltip/Tooltip";
import Modal from "react-modal";

const columnsData = [
  {
    name: "Intervention Logic Name",
    selector: (row) => (
      <ToolTip label={`${row.interventionLogicName}`}>
        {row.interventionLogicName}
      </ToolTip>
    ),
  },

  {
    name: "Intervention Logic Type",
    selector: (row) => (
      <ToolTip label={`${row.interventionLogicType}`}>
        {row.interventionLogicType}
      </ToolTip>
    ),
  },
];

const AddSpecificObjective = () => {
  let { id } = useParams();
  const projectId = id;
  const dispatch = useDispatch();
  const { formFields } = useSelector((state) => state.fields);
  const {
    created,
    loading,
    response = {},
    data = {},
  } = useSelector((state) => state.records);
  const { specificObjectives = {} } = useSelector(
    (state) => state.records?.current
  );

  const [activityModalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const tableData = specificObjectives?.data?.items ?? [];

  //by default clear previous step form data
  useEffect(() => {
    dispatch({ type: clearForm.type });
  }, []);

  useEffect(() => {
    getSpecificObjectives();
  }, [created]);

  const specificObjectiveColumns = [
    ...columnsData,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Edit">
            <Icon.PencilSquare
              className="action-icons"
              onClick={() => openModal(row)}
            />
          </ToolTip>
          <DeleteRow row={row} url="/InterventionLogic" />
        </>
      ),
    },
  ];

  const onAdd = (params) => {
    let formData = {
      ...params,
      interventionLogicType: {
        value: INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE,
      },
      projectId: { value: projectId },
    };
    dispatch({
      type: createRecord.type,
      payload: { ...formData, url: "/InterventionLogic" },
    });
  };
  function toggleModal() {
    setModalIsOpen(!activityModalIsOpen);
  }

  function openModal(row = null) {
    toggleModal();
    if (row) {
      setCurrentRow(row);
      dispatch({ type: formatRecords.type, payload: { ...row } });
    }
  }

  function getSpecificObjectives() {
    if (projectId) {
      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/InterventionLogic/GetSpecificObjectives?ProjectId=${projectId}`,
          appendCurrentReducerKey: "specificObjectives",
        },
      });
    }
  }

  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }

  function closeActivityModal(row = null) {
    toggleModal();
    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
  }

  function postData(params) {
    let formData = { ...params, Id: { value: currentRow?.id } };
    dispatch({
      type: putRecord.type,
      payload: { ...formData, url: "/InterventionLogic" },
    });

    toggleModal();
  }

  return (
    <div className="position-relative">
      <Loader loading={loading} theme="light" />
      <Form
        formData={getFieldsByCategory(formFields, "intervention")}
        onSubmit={(params) => onAdd(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
      <hr />
      <div>
        <div className="font-weight-bold">Specific Objectives List</div>

        <Table columns={specificObjectiveColumns} data={tableData} />
      </div>

      <Modal
        isOpen={activityModalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Specific Objectives"
      >
        <Icon.XCircle onClick={closeActivityModal} className="action-icons" />
        <h4>Specific Objectives</h4>
        <Form
          formData={getFieldsByCategory(formFields, "intervention-update")}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </div>
  );
};

export default AddSpecificObjective;
