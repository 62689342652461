import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import { clearForm, createRecord, formatRecords, getRecords } from "../../redux/reducers";
import { activityFields } from "../../assets/fields/activityFields";

import Form from "../form/Form";
import Loader from "../loader/loader";

import { getFormatedDate } from "../../Utility";
import DataTable from "react-data-table-component";
import DeleteRow from "../../pages/DeleteRow";
import ActivityModal from "./ActivityModal";

const ActivityColumns = [
  {
    name: "Name",
    selector: (row) => row?.name,
  },
  {
    name: "Thematic Areas",
    selector: (row) => row?.thematicArea?.name,
  },
  {
    name: "Expected Result",
    selector: (row) => row?.expectedResult?.name,
  },

];
const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#EFF5FD",
      paddingBottom: 10,
      paddingTop: 10,
    },
  },
  cells: {
    style: {},
  },
};

const AddActivity = ({ projectId = "" }) => {
  const dispatch = useDispatch();
  const { formFields } = useSelector((state) => state.fields);
  const {projectInfo} = useSelector((state) => state.records?.current);
  const { created, loading } = useSelector((state) => state.records);
  const { activityTable = {} } = useSelector((state) => state.records?.current);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [activityModalIsOpen, setActivityModalIsOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = useState({});


  // const [customField,setCustomFields]= useState(formFields)

  //by default clear previous step form data
  useEffect(() => {
    dispatch({ type: clearForm.type });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/${projectId}`,
        appendCurrentReducerKey: "projectInfo",
      },
    });
  }, []);

  useEffect(() => {
    getActvityData();
}, [created])

let columns = [
  ...ActivityColumns,
  {
    name: "Action",
    selector: (row) => (
      <>
        <Icon.PencilSquare
          className="action-icons"
          onClick={() => openActivityModal(row)}
        />
        <DeleteRow row={row} url={`/ERActivity`} />
      </>
    ),
  },
];

function openActivityModal(row = null) {
toggleActivityModal();
  if (row) {
    setCurrentRow(row);
    dispatch({ type: formatRecords.type, payload: { ...row } });
  }
}

function closeActivityModal(row = null) {
  toggleActivityModal();
  setCurrentRow({});
  dispatch({ type: "form/clearForm" });
}

function getActvityData() {
    dispatch({
        type: "records/getRecords",
        payload: {
            url: `/ERActivity/GetActivities?projectId=${projectId}`,
            appendCurrentReducerKey: "activityTable",
        },
    });
}

//   useEffect(()=>{
//     const fieldsdata = getFieldsByCategory(formFields, "activities");
//     const formattedFields = (fieldsdata ?? [])?.map((field) => {
//       if (field?.id === "thematicAreas") {
//         return {
//           ...field,
//           options: projectInfo?.data?.thematicAreas?.map((data) => ({
//             label: data?.name,
//             value: data?.id,
//           })),
//         };
//       }
//       if (field.name == "expectedResult"){
//         return { ...field, url: field.url + projectId };
//       }
//       return field;
//     });
    
//     setCustomFields(formattedFields);
//   }
  
// ,[projectInfo])


  const onAdd = (params) => {
    let formData = { ...params, projectId: { value: projectId } };
    dispatch({
      type: createRecord.type,
      payload: { ...formData, url: "/ERActivity" },
    });
  };

  function toggleActivityModal() {
    setActivityModalIsOpen(!activityModalIsOpen);
  }

  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }

  function formatFields() {
    let fields = getFieldsByCategory(formFields, "activities");
    return fields.map((field) => {
      if (field?.id === "thematicAreas") {
        return {
          ...field,
          options: projectInfo?.data?.thematicAreas?.map((data) => ({
            label: data?.name,
            value: data?.id,
          })),
        };
      }
      if (field.name == "expectedResult"){
        return { ...field, url: field?.url + projectId };
      }
      
      return field;
    });
  }


  return (
    <div className="position-relative">
      {/* <Loader loading={loading} theme="light" /> */}
      <Form
        formData={formatFields()}
        onSubmit={(params) => onAdd(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
      <div>
      <h5 className="font-weight-bold">Activities</h5>
      <DataTable
        columns={columns}
        data={activityTable?.data?.items ?? []}
        customStyles={customStyles}
        pagination
        dense
      />
       <ActivityModal
        modalIsOpen={activityModalIsOpen}
        activityFields={formatFields()}
        toggleActivityModal={() => closeActivityModal()}
        projectId={projectId}
        rowId={currentRow?.id}
      />

      </div>
     
    </div>
  );
};
export default AddActivity;
