import React, { useEffect, useRef } from "react";
import "./RecentActivitiesGallery.scss";
import { Carousel } from "bootstrap";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import { baseUrl } from "../../../redux/index";

const ReacentActivitiesGallery = () => {
  const carouselRef = useRef();
  const dispatch = useDispatch();

  const { recentImages = { data: { items: [] } } } = useSelector(
    (state) => state.records?.current || {}
  );

  const getRecentImages = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetRecentlyUploadedPictures`,
        appendCurrentReducerKey: "recentImages",
      },
    });
  };

  useEffect(() => {
    new Carousel(carouselRef.current);
    setTimeout(() => {
      getRecentImages();
    }, 200);
  }, [dispatch]);

  return (
    <div className="bg-white shadow-sm p-3 h-100 activities-container">
      <Label>Recent Activities Gallery</Label>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        ref={carouselRef}
      >
        <div className="carousel-indicators">
          {recentImages.data.items.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        {recentImages.data.items.length > 0 && (
          <div
            id="carouselControlId"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {recentImages.data.items.map((item, index) => (
                <div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <img
                    src={`${baseUrl}${item?.url}`}
                    className="d-block w-100"
                    alt={item?.caption || "Image"}
                  />
                </div>
              ))}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselControlId"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselControlId"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReacentActivitiesGallery;
