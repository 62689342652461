import ToolTip from "../../components/tooltip/Tooltip";
import { truncateText } from "../../utils/util";

const DebriefColumns = [
  // {
  //   name: "Monitoring Visit Report",
  //   selector: (row) => row?.monitoringVisitReport?.name,
  // },
  {
    name: "Parent DeBrief",
    selector: (row) => 
    <ToolTip label={`${row?.parentDebrief?.deBriefMemo}`}>{
      truncateText(row?.parentDebrief?.deBriefMemo ?? "", 20)}
    </ToolTip>

  },
  {
    name: "Challenge",
    selector: (row) =>
      <ToolTip label={` ${row?.keyChallenge}`}>
        { truncateText(row?.keyChallenge ?? "", 20)}
      </ToolTip>

  },
  {
    name: "Memo",
    selector: (row) => 
    <ToolTip label={`${row?.deBriefMemo}`}>{
      truncateText(row?.deBriefMemo ?? "", 20)}
    </ToolTip>

  },
  {
    name: "Response",
    selector: (row) => 
    <ToolTip label={`${row?.response}`}>{
      truncateText(row?.response ?? "", 20)}
    </ToolTip>
    

  },
  {
    name: "Timeline",
    selector: (row) => 
    <ToolTip label={`${row?.timeline}`}>{
      truncateText(row?.timeline ?? "", 20)}
    </ToolTip>

  },
  {
    name: "Action Point",
    selector: (row) => 
    <ToolTip label={`${row?.actionPoint}`}>{
      truncateText(row?.actionPoint ?? "", 20)}
    </ToolTip>

  },
];

export default DebriefColumns;
