import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import TARGET_TYPE from "../../assets/enums/TARGET_TYPE";

import { subActivityAchievementFields } from "../../assets/fields/subActivityAchievementFields";
import { createRecord, resetData } from "../../redux/reducers";
import Form from "../form/Form";
import Loader from "../loader/loader";
import "./Activities.scss";
import ToolTip from "../tooltip/Tooltip";

const SubActivityAchievementModal = ({
  modalIsOpen,
  selectedSubActvity,
  toggleSubActivityAchievementModal,
}) => {
  const dispatch = useDispatch();
  const { loading, created } = useSelector((state) => state.records);

  const params = useParams();

  useEffect(() => {
    if (created) {
    }
    toggleSubActivityAchievementModal();

    return () => {
      // dispatch({ type: resetData.type })
    };
  }, [created]);

  function postData(params) {
    const formValues = {
      ...params,
      SubActivity: { id: selectedSubActvity.id, name: selectedSubActvity.name },
    };
    dispatch({
      type: createRecord.type,
      payload: {
        ...formValues,
        url: "/SubActivityAchievement",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  }

  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }

  const formatFields = getFieldsByCategory(
    subActivityAchievementFields,
    selectedSubActvity?.targetType === TARGET_TYPE.BENEFICIARIES
      ? "benef"
      : "non-benef"
  ).map((field) => {
    if (field?.name === "BeneficiaryIds") {
      const newColumns = [
        ...field?.columns,
        {
          name: "Actions",
          selector: (row) => (
            <>
              <ToolTip label={`Remove This Beneficairy`}>
                <Icon.Trash
                  className="action-icons"
                  onClick={() => {
                   
                    dispatch({
                      type: createRecord.type,
                      payload: {
                     
                        url: `/Beneficiary/SetSubActivityBeneficiaries?subActivityId=${row?.subActivityId}&beneficiayId=${row?.beneficiaryId}`,
                      },
                    });
                  }}
                />
              </ToolTip>
            </>
          ),
        },
      ];

      return {
        ...field,
        columns: newColumns,
        url: field.url + selectedSubActvity.id,
      };
    }

    return field;
  });

  // const formatFields = subActivityAchievementFields
  //   .filter(
  //     (item) =>
  //       (selectedSubActvity?.targetType == TARGET_TYPE.BENEFICIARIES &&
  //         item?.category !== "NumbersAchieved") ||
  //       (selectedSubActvity?.targetType == TARGET_TYPE.AMOUNT &&
  //         item?.name !== "BeneficiaryIds")
  //   )
  //   .map((field) => {
  //     if (field.name === "BeneficiaryIds")
  //       return { ...field, url: field.url + selectedSubActvity.id };

  //     return field;
  //   });

  if (selectedSubActvity?.targetType == 0)
    subActivityAchievementFields.splice(0, 0);
  // if (selectedSubActvity?.targetType == 1)
  //   subActivityAchievementFields.splice(1, 1);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
      >
        <Icon.XCircle
          onClick={toggleSubActivityAchievementModal}
          className="action-icons"
        />
        <h4>{selectedSubActvity?.name}</h4>
        <Loader loading={loading} />
        <Form
          formData={formatFields}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </>
  );
};

export default SubActivityAchievementModal;
