import React from "react";
import Loader from "../loader/loader";
import "./RecordsTable.scss";

const calculatedColumn = (calc, value) => {
  const pattren = /\$([a-zA-Z_\x7f-\xff][a-zA-Z0-9_\x7f-\xff]*)/g;
  let lastMatch;
  let result = calc;
  while (pattren.global && (lastMatch = pattren.exec(calc))) {
    const toReplace = lastMatch[0];
    const replaceWithIndex = lastMatch[1];
    const replaceWith = value[replaceWithIndex];
    result = result.replace(toReplace, replaceWith);
  }
  return eval(result);
};
const RecordsTable = ({ header, actionButtons, ...props }) => {
  const { className, icon, data, loading, loader } = props;
  return (
    <>
      {!!loading ? (
        <span>{loader ? loader : <Loader />}</span>
      ) : (
        <table className={`records-table ${className}`}>
          <thead>
            <tr>
              {!!header &&
                header.map(({ label }, index) => {
                  return <th key={index}>{label}</th>;
                })}
              {actionButtons && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {!!data &&
              data.map((value, index) => {
                return (
                  <tr key={index}>
                    {header.map(({ name, calc }, j) => {
                      let displayed = value[name];
                      if (typeof displayed == "object") {
                        displayed = displayed?.name;
                      }
                      if (calc) {
                        displayed = calculatedColumn(calc, value);
                      }
                      return <td key={`${index}-${j}`}>{displayed}</td>;
                    })}
                    {!!actionButtons && (
                      <td className="act-col flex justify-space-evently">
                        {actionButtons.map((btn, i) => {
                          let _className = "";
                          const { onClick, className, children } = btn.props;
                          let elem = null;
                          const { overRideIcon, ...childProps } =
                            children.props;
                          if (typeof overRideIcon == "function") {
                            childProps.iconName = overRideIcon(value);
                          }
                          const child = React.cloneElement(children, {
                            ...childProps,
                          });
                          if (typeof className == "function") {
                            _className = className(value);
                          }
                          if (typeof onClick == "function") {
                            elem = React.cloneElement(btn, {
                              onClick: (e) => onClick(e, value, index),
                              className: _className,
                              children: child,
                            });
                          }

                          return (
                            <React.Fragment key={`${index}-${i}`}>
                              {elem}
                            </React.Fragment>
                          );
                        })}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default RecordsTable;
