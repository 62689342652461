import React, { useEffect, useState } from "react";
import "./DashboardNavBar.scss";
import { CaretRightFill, Funnel } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_TAB } from "../../Constants";
import Accordion from "react-bootstrap/Accordion";
import ToolTip from "../tooltip/Tooltip";
import ReactSelect from "react-select";
import { getRecords } from "../../redux/reducers";
import { Form } from "react-bootstrap";
import FilterAccordian from "../accordian/FilterAccordian";
import * as Icon from "react-bootstrap-icons";
import DashboardFilters from "./DashboardFilters";
import { DashboardFilterFields } from "../../assets/fields/DashboardFilterFields";
import { MainDashboardPagefiltersItems } from "../../utils/util";
import ExportData from "./ExportData";

const DashboardTopNavBar = () => {
  const dispatch = useDispatch();
  const { currentActiveTab } = useSelector((state) => state?.setting?.filter);

  // const [showDropdown, setShowDropdown] = useState(false);
  // const [showReportDropdown, setShowReportDropdown] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const tabs = [
    ACTIVE_TAB.Dashboard,
    ACTIVE_TAB["Geographical Data"],
    ACTIVE_TAB.Indicators,
    ACTIVE_TAB.Activities,
    ACTIVE_TAB.Complaints,
  ];

  function setActiveTab(tab) {
    dispatch({
      type: "setting/setFilter",
      payload: {
        currentActiveTab: tab,
      },
    });
  }


  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown); // Toggle dropdown
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
      <div className="container-fluid">
        <div className="navbar-nav">
          {tabs.map((tab, index) => (
            <div key={index}>
              <button className="nav-btn" onClick={() => setActiveTab(tab)}>
                {tab}
              </button>
              {currentActiveTab === tab && (
                <div className="nav-indicator"></div>
              )}
            </div>
          ))}
        </div>

        <div className="mr-2 d-flex">
          <div className=" px-2" >
            <ToolTip label="Filter">
              <div className="dropdown">
                <button
                  className="btn btn-primary ml-auto d-flex mb-2 align-items-center dropdown-toggle"
                  onClick={() => toggleDropdown("filter")}
                  aria-expanded={openDropdown === "filter"}
                >
                  <Icon.Funnel size={20} />
                  <span className="ml-2">Filter</span>
                </button>
                <DashboardFilters
                  showDropdown={openDropdown === "filter"}
                  handleClose={() => setOpenDropdown(null)}
                  formFields={DashboardFilterFields}
                  url={MainDashboardPagefiltersItems}
                />
              </div>
            </ToolTip>
          </div>
          <div className="px-2">
            <ToolTip label="Download Reports">
              <div className="dropdown">
                <button
                  className="btn btn-danger ml-auto d-flex mb-2 align-items-center dropdown-toggle "
                  onClick={() => toggleDropdown("reports")}
                  aria-expanded={openDropdown === "reports"}
                >
                  <Icon.FileEarmarkBarGraph size={20} />
                  <span className="ml-2">Reports</span>
                </button>

                 <ExportData  
                  showDropdown={openDropdown === "reports"}
                  handleClose={() => setOpenDropdown(null)}
                  />
                
              </div>
            </ToolTip>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardTopNavBar;
