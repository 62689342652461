import React from "react";
import Chart from "react-apexcharts";

const RadialBarAngleCircle = ({
  attributes,
  labels = [],
  series = [],
  ...elementProps
}) => {
  let options = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "30%",
          background: "transparent",
          image: undefined
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          }
        }
      }
    },
    // colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
    labels,
    legend: {
      show: true,
      floating: true,
      fontSize: "12px",
      position: "bottom",
      align: "left",
     
      labels: {
        useSeriesColors: true
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3
      }
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
        autoSelected: 'zoom' 
      },
  }
  };

  return (
    <Chart
      type="radialBar"
    
      series={series}
      options={options}
      responsive={[
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]}
    />
  );
};

export default RadialBarAngleCircle;
