export const smartIndicatorFields = [
  {
    label: "Specific Objective",
    placeholder: "Select Option",
    name: "interventionLogic",
    inputType: "async-dropdown",
    url: "/InterventionLogic/GetSpecificObjectives",
    searchAttrib: "name",
    id: "interventionLogic",
    labelAttribute: "interventionLogicName",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Indicator",
    placeholder: "Add Smart Indicator",
    inputType: "textarea",
    inForm: true,
    name: "indicator",
    wrapperClass: "col-md-6",
  },
  {
    label: "Definition",
    placeholder: "Add Indicator Definition",
    inputType: "textarea",
    inForm: true,
    required: false,
    name: "definition",
    wrapperClass: "col-md-6",
  },
  {
    label: "Indicator Target",
    placeholder: "Add Indicator Target",
    inputType: "text",
    type: "number",
    inForm: true,
    name: "indicatorTarget",
    wrapperClass: "col-md-6",
  },
  {
    label: "Total Target",
    placeholder: "Add Total Target",
    inputType: "text",
    type: "number",
    inForm: true,
    name: "totalTarget",
    wrapperClass: "col-md-6",
  },
  {
    label: "Baseline Value",
    placeholder: "Add Baseline Value",
    inputType: "text",
    type: "number",
    inForm: true,
    name: "baselineValue",
    wrapperClass: "col-md-6",
  },
  {
    label: "Indicator Achievement Date",
    placeholder: "Enter Date",
    name: "indicatorAchievementDate",
    inputType: "date",
    inForm: true,
    wrapperClass: "col-md-6",
    required: true,
  },
  // {
  //     "label": "Intervention Logic",
  //     "inputType": "tableInput",
  //     "inForm": true,
  //     "name": "interventionLogic",
  //     "header": [
  //         {
  //             id: "name",
  //             label: "Intervention Logic Name",
  //             name: "name",
  //             placeholder: "Enter Name",
  //         },
  //     ],
  //     "wrapperClass": "col-12"
  // },
  {
    label: "Responsible Person",
    placeholder: "Select Responsible Person",
    name: "responsiblePerson",
    inputType: "async-dropdown",
    url: "/UserManagement/GetAll ",
    searchAttrib: "name",
    id: "responsiblePerson",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    name: "periodicity",
    label: "Periodicity",
    placeholder: "Select Periodicity",
    inputType: "async-dropdown",
    url: "/Periodicity/GetAll",
    searchAttrib: "name",
    id: "periodicity",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Assumption/Risks",
    inForm: true,
    name: "siAssumptionOrRisks",
    placeholder: "Add Assumption/Risks",
    inputType: "async-dropdown",
    url: "/AssumptionOrRisk/GetAll",
    searchAttrib: "name",
    id: "siAssumptionOrRisks",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  {
    label: "Mean Of Verifications",
    inForm: true,
    name: "siMeanOfVerifications",
    placeholder: "Add Mean Of Verifications",
    inputType: "async-dropdown",
    url: "/MeansOfVerification/GetAll",
    searchAttrib: "name",
    id: "siMeanOfVerifications",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  // {
  //     "label": "Mean Of Verifications",
  //     "inputType": "tableInput",
  //     "inForm": true,
  //     "name": "siMeanOfVerifications",
  //     "header": [
  //         {
  //             id: "name",
  //             label: "Name",
  //             name: "name",
  //             placeholder: "Enter Name",
  //         },
  //     ],
  //     "wrapperClass": "col-md-6"
  // },
  {
    inputType: "p",
    buttonType: "submit",
    children: "",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper my-2 ml-3",
    className: "btn btn-primary",
  },
];
