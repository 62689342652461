import React from "react";
import "./ActivitiesChart.scss";
import ActivitiesChart from "./ActivitiesChart";

const MainActivitiesTab = () => {
  return (
    <div class="container-fluid p-0 my-4">
      <div class="row px-0 g-3 row-container">
        <div class="col-12 col-map">
          <ActivitiesChart />
        </div>
      </div>
    </div>
  );
};

export default MainActivitiesTab;
