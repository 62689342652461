export const fundingLineFields = [
  {
    "label": "Organization Name",
    "placeholder": "Select Organization Name",
    "name": "fundingOrganization",
    "id": "fundingOrganization",
    "inputType": "async-dropdown",
    "url": "/FundingOrganization/GetAll",
    "searchAttrib": "name",
    "labelAttribute": "name",
    "valueAttribute": "id",
    "onChangeMethod": "asyncdropdown",
    "inForm": true,
    "wrapperClass": "col-6",
    "isMulti": false
  },
  {
    "label": "Amount",
    "placeholder": "Enter Amount",
    "inForm": true,
    "type": "number",
    "name": "amount",
    "wrapperClass": "col-6"
  },
  // {
  //   "label": "Total Amount",
  //   "type": "number",
  //   "placeholder": "Enter Total Amount",
  //   "inForm": true,
  //   "name": "totalAmountLeft",
  //   "wrapperClass": "col-6"
  // },
  {
    "label": "Remarks",
    "placeholder": "Enter Remarks",
    "inForm": true,
    "name": "remarks",
    "wrapperClass": "col-6"
  },
  {
    "type": "hidden",
    "inForm": true,
    "wrapperClass": "col-12"
  },
  {
    "inputType": "button",
    "buttonType": "submit",
    "children": "Submit",
    "inForm": true,
    "wrapperClass": "mt-3 mr-3 btn-wrapper position-right"
  }
]