import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import Table from "../../components/table/Table";
import Loader from "../../components/loader/loader";
import MonitoringVisitColumns from "../../assets/columns/MonitoringVisitColumns";
import DeleteRow from "../DeleteRow";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import SearchList from "../../components/search-list/SearchList";
import ListHeader from "../../components/list-header/ListHeader";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import Searchbar from "../../serachbar/Searchbar";

const MonitoringVisitReports = () => {
  const columns = [
    ...MonitoringVisitColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link to={`${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          <DeleteRow row={row} url="/MonitoringVisitReport" />
        </>
      )
    }
  ];

  const { setMeta } = usePageMeta();
  const dispatch = useDispatch();
  const { loading, data, created } = useSelector((state) => state.records);
  const { items = [] } = data;

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/MonitoringVisitReport/GetAll",
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC }
      }
    });
  }, [created]);

  useEffect(() => {
    setMeta({ title: "Monitoring Visit Reports" });
  }, []);

  return (
    <>
      <Searchbar
        className="mb-3"
        searchListParams={{
          url: "/MonitoringVisitReport/GetAll",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
          searchFieldName: "search.value",
        }}
        listHeaderParams={{
          options: [{
            id: 1,
            name: "PlusCircle",
            url: "/monitoring-visit-report/add",
            itemClassName: "btn btn-success d-flex align-items-center justify-content-center",
            iconColor: "#fff",
            label: "Add Monitoring Visit Report",
            text: "Add Report",
          }],
        }}
      />

      {/* Table */}
      <div className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} data={items && items} canExport={true} />
      </div>
    </>
  );
};

export default MonitoringVisitReports;
