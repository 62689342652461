import React, { useEffect, useState } from "react";
import "./DashboardPage.scss";
// import DonutChart from "../../components/chart/DonutChart";
import SDGChart from "../../components/chart/SDGChart";
import SimplePieChart from "../../components/chart/SimplePieChart";
import BarChartAppex from "../../components/chart/BarChartAppex";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";
import useMediaQuery from "../../hooks/useMediaQuery";
import { getBarChartFormattedDate, getPieFormatedData } from "../../utils/util";
import usePageMeta from "../../hooks/usePageMeta";
import DashboardTopNavBar from "../../components/dashboard/DashboardNavBar";
import DashboardTabPages from "../../components/dashboard/DashboardTabPages";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const [projectId, setProjectId] = useState(null);

  const {
    gendersCount = "",
    activitiesCount = "",
    projectList,
  } = useSelector((state) => state.records.current);

  useEffect(() => {
    setMeta({ title: "Home / Dashboard" });
    getBeneficiaryCountByGenders();
    setTimeout(() => {
      getProjectList();
    }, 200);
    setTimeout(() => {
      getBeneficiaryCountByActivies();
    }, 400);
  }, [projectId]);

  const getProjectList = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/project/GetAll",
        appendCurrentReducerKey: "projectList",
      },
    });
  };

  const getBeneficiaryCountByGenders = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/Dashboard/GetBeneficiaryCountByGenders?Project.Id=${projectId}`
          : `/Dashboard/GetBeneficiaryCountByGenders`,
        appendCurrentReducerKey: "gendersCount",
      },
    });
  };

  const getBeneficiaryCountByActivies = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/Dashboard/GetBeneficiaryCountByActivities?Project.Id=${projectId}`
          : `/Dashboard/GetBeneficiaryCountByActivities`,
        appendCurrentReducerKey: "activitiesCount",
      },
    });
  };

  const genderCountData = getPieFormatedData(gendersCount?.data);
  const activitiesCountData = getBarChartFormattedDate(
    activitiesCount?.data?.items
  );

  return (
    <div className="main">
      <DashboardTopNavBar />
      <DashboardTabPages />
    </div>
  );
};

export default DashboardPage;
