import * as Icon from "react-bootstrap-icons";
import { baseUrl } from "../../redux";
import { getFormatedDate } from "../../Utility";
const MealColumns = [
  {
    name: "Officer Name",
    selector: (row) => row?.mealOfficer?.name
  },
  // {
  //   name: "Project Title",
  //   selector: (row) => (
  //     <Link to={`${row?.id}`}>
  //       {" "}
  //       <TableTitle key={row?.id} title={row?.project?.name} />
  //     </Link>
  //   )
  // },
  {
    name: "Location",
    selector: (row) => row?.tehsil?.name
  },
  {
    name: "Start Date",
    selector: (row) => getFormatedDate(row?.startDate)
  },
  {
    name: "End Date",
    selector: (row) => getFormatedDate(row?.endDate)
  },
  {
    name: "Attachments",
    selector: (row) => (
      <div className="pl-4">
        {row?.attachment?.url ? <a href={`${baseUrl}${row?.attachment?.url}`} target="_blank">
          <Icon.FileArrowDown size="20" className="action-icons" />
        </a> : "No Attachment"}
      </div>
    )
  }
];
export default MealColumns;
