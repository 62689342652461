import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { USER_ROLE } from "../Constants";
import { canView, getCurrentUserRole } from "../utils/util";

const useUserRole = () => {
  const { user } = useSelector((state) => state.setting);
  const userRole = getCurrentUserRole(user);
  const [role, setRole] = useState({
    isManagement: false,
    isAdmin: false,
    isManager: false,
    isAssistant: false,
    isOfficer: false,
    isSuperAdmin: false,
    isProjectManager: false,
    isCountryManager: false,
    isBaseCountryManager: false,
    isBaseProjectManager: false,
  });
  const getUserRole = () => {
    const isManagement = canView([USER_ROLE.MANAGEMENT], userRole);
    const isAdmin = canView([USER_ROLE.ADMIN], userRole);
    const isSuperAdmin = canView([USER_ROLE.SUPER_ADMIN], userRole);
    const isProjectManager = canView([USER_ROLE.PROJECT_MANAGER], userRole);
    const isCountryManager = canView([USER_ROLE.COUNTRY_MANAGER], userRole);
    const isBaseCountryManager = canView(
      [USER_ROLE.BASE_COUNTRY_MANAGER],
      userRole
    );
    const isBaseProjectManager = canView(
      [USER_ROLE.BASE_PROJECT_MANAGER],
      userRole
    );
    const isManager = canView([USER_ROLE.MANAGER], userRole);
    const isAssistant = canView([USER_ROLE.ASSITANT], userRole);
    const isOfficer = canView([USER_ROLE.OFFICER], userRole);

    setRole({
      isManagement,
      isAdmin,
      isManager,
      isAssistant,
      isOfficer,
      isSuperAdmin,
      isProjectManager,
      isCountryManager,
      isBaseCountryManager,
      isBaseProjectManager,
    });
  };

  useEffect(() => {
    getUserRole();
  }, []);
  return role;
};

export default useUserRole;
