import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
const BeneficiariesReportPDF = ({data =[]}) => {
  // console.log("🚀 ~ BeneficiariesReportPDF ~ data:", data);
  // const data = [
  //   {
  //     projectId: 30047,
  //     totalBeneficiaries: 9,
  //     pwdMaleCount: 1,
  //     pwdFemaleCount: 0,
  //     ageGroups: {
  //       ageGroup_0_17: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 0,
  //         men: 0,
  //         women: 0,
  //         total: 0,
  //       },
  //       ageGroup_18_25: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 1,
  //         men: 0,
  //         women: 0,
  //         total: 1,
  //       },
  //       ageGroup_26Plus: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 0,
  //         men: 8,
  //         women: 0,
  //         total: 8,
  //       },
  //     },
  //     thematicAreas: [
  //       {
  //         thematicAreaDescription: "Food Security and Livelihoods",
  //         totalBeneficiaries: 9,
  //       },
  //       {
  //         thematicAreaDescription: "Shelter",
  //         totalBeneficiaries: 9,
  //       },
  //     ],
  //   },
  //   {
  //     projectId: 30047,
  //     totalBeneficiaries: 9,
  //     pwdMaleCount: 1,
  //     pwdFemaleCount: 0,
  //     ageGroups: {
  //       ageGroup_0_17: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 0,
  //         men: 0,
  //         women: 0,
  //         total: 0,
  //       },
  //       ageGroup_18_25: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 1,
  //         men: 0,
  //         women: 0,
  //         total: 1,
  //       },
  //       ageGroup_26Plus: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 0,
  //         men: 8,
  //         women: 0,
  //         total: 8,
  //       },
  //     },
  //     thematicAreas: [
  //       {
  //         thematicAreaDescription: "Food Security and Livelihoods",
  //         totalBeneficiaries: 9,
  //       },
  //       {
  //         thematicAreaDescription: "Shelter",
  //         totalBeneficiaries: 9,
  //       },
  //     ],
  //   }, {
  //     projectId: 30047,
  //     totalBeneficiaries: 9,
  //     pwdMaleCount: 1,
  //     pwdFemaleCount: 0,
  //     ageGroups: {
  //       ageGroup_0_17: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 0,
  //         men: 0,
  //         women: 0,
  //         total: 0,
  //       },
  //       ageGroup_18_25: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 1,
  //         men: 0,
  //         women: 0,
  //         total: 1,
  //       },
  //       ageGroup_26Plus: {
  //         boys: 0,
  //         girls: 0,
  //         youthMale: 0,
  //         youthFemale: 0,
  //         men: 8,
  //         women: 0,
  //         total: 8,
  //       },
  //     },
  //     thematicAreas: [
  //       {
  //         thematicAreaDescription: "Food Security and Livelihoods",
  //         totalBeneficiaries: 9,
  //       },
  //       {
  //         thematicAreaDescription: "Shelter",
  //         totalBeneficiaries: 9,
  //       },
  //     ],
  //   },
  // ];
  const styles = StyleSheet.create({
    body: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingHorizontal: 10,
    },
    header: {
      fontSize: 12,
      textAlign: "left",
      marginTop: 20,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      fontSize: 10,
    },
    cell: {
      borderWidth: 1,
      borderColor: "black",
      padding: 5,
      flex: 1,
      fontSize: 8,
      textAlign: "center",
    },
    seperator: {
      paddingTop: 20,
    },
    fontBold:{
      fontWeight: "700"
    },
    cellYellow: {
      backgroundColor: "yellow",
      borderWidth: 1,
      borderColor: "black",
      padding: 5,
      flex: 1,
      fontSize: 8,
      textAlign: "center",
    },
    cellGreen: {
      backgroundColor: "lightgreen",
      borderWidth: 1,
      borderColor: "black",
      padding: 5,
      flex: 1,
      fontSize: 8,
      textAlign: "center",
    },
    cellBlue: {
      backgroundColor: "lightblue",
      borderWidth: 1,
      borderColor: "black",
      padding: 5,
      flex: 1,
      fontSize: 8,
      textAlign: "center",
    },
    cellPink: {
      backgroundColor: "lightpink",
      borderWidth: 1,
      borderColor: "black",
      padding: 5,
      flex: 1,
      fontSize: 8,
      textAlign: "center",
    },
  });
  return (
    <>
      <Document>
        <Page style={styles.body}>
          {/* Header */}
          {data.map((item, index) => {
            return (
              <View key={index} break={(index > 0 && index  % 2 === 0) ? true : false}>
                <Text style={styles.header}>
                  Project Name : {item.projectId}
                </Text>

                {/* SECTORS Section */}
                <View style={[styles.row, styles.seperator]}>
                  <View style={styles.cellYellow}>
                    <Text>SECTORS</Text>
                  </View>
                  <View style={styles.cellGreen}>
                    <Text>Child Protection</Text>
                    <Text>Education</Text>
                    <Text>&amp; well-being</Text>
                  </View>
                  <View style={styles.cellGreen}>
                    <Text>Food Security and Livelihoods</Text>
                  </View>
                  <View style={styles.cellGreen}>
                    <Text>WASH</Text>
                  </View>
                  <View style={styles.cellGreen}>
                    <Text>Shelter</Text>
                  </View>
                </View>
                <View style={[styles.row]}>
                  <View style={styles.cell}>
                    <Text>Nb of beneficiaries</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>
                      {item?.thematicAreas[0]?.totalBeneficiaries ?? 0}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>
                      {item?.thematicAreas[1]?.totalBeneficiaries ?? 0}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>
                      {item?.thematicAreas[2]?.totalBeneficiaries ?? 0}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>
                      {item?.thematicAreas[3]?.totalBeneficiaries ?? 0}
                    </Text>
                  </View>
                </View>
                {/* People with Disability */}

                <View style={[styles.row, styles.seperator]}>
                  <View style={[styles.cellBlue, { flex: 1 }]}>
                    <Text>PEOPLE WITH</Text>
                    <Text>DISABILITIES</Text>
                  </View>
                  <View style={styles.cellBlue}>
                    <Text>Male</Text>
                  </View>
                  <View style={styles.cellBlue}>
                    <Text>Female</Text>
                  </View>
                  <View style={[styles.cellBlue]}>
                    <Text  style={ styles.fontBold}>Total</Text>
                  </View>
                </View>
                <View style={[styles.row]}>
                  <View style={styles.cell}>
                    <Text></Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>{item?.pwdMaleCount ?? 0}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>{item?.pwdFemaleCount ?? 0}</Text>
                  </View>
                  <View style={[styles.cell]}>
                    <Text style={ styles.fontBold}>{item?.pwdMaleCount + item?.pwdFemaleCount}</Text>
                  </View>
                </View>

                STATUS Section
                <View style={[styles.row, styles.seperator]}>
                  <View style={styles.cellYellow}>
                    <Text>STATUS</Text>
                  </View>
                  <View style={styles.cellYellow}>
                    <Text>IDPs</Text>
                  </View>
                  <View style={styles.cellYellow}>
                    <Text>Residents /</Text>
                    <Text>Host Community</Text>
                  </View>
                  <View style={styles.cellYellow}>
                    <Text>Refugees</Text>
                  </View>
                  <View style={styles.cellYellow}>
                    <Text>Returnees</Text>
                    <Text>(from within the country)</Text>
                  </View>
                  <View style={styles.cellYellow}>
                    <Text>Returnees</Text>
                    <Text>(from outside of the country)</Text>
                  </View>
                </View>
                <View style={[styles.row]}>
                  <View style={styles.cell}>
                    <Text>Nb of beneficiaries</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0</Text>
                  </View>
                </View>

                {/* SEX Section */}
                <View style={[styles.row, styles.seperator]}>
                  <View style={styles.cellYellow}>
                    <Text>SEX</Text>
                  </View>
                  <View style={styles.cellPink}>
                    <Text>Boys</Text>
                  </View>
                  <View style={styles.cellPink}>
                    <Text>Girls</Text>
                  </View>
                  <View style={[styles.cellPink]}>
                     <Text style={ styles.fontBold}>Total</Text>
                    <Text>Children</Text>
                  </View>
                  <View style={styles.cellPink}>
                    <Text>Youth</Text>
                    <Text>(male)</Text>
                  </View>
                  <View style={styles.cellPink}>
                    <Text>Youth</Text>
                    <Text>(female)</Text>
                  </View>
                  <View style={[styles.cellPink]}>
                     <Text style={ styles.fontBold}>Total</Text>
                    <Text>Youth</Text>
                  </View>
                  <View style={styles.cellPink}>
                    <Text>Women</Text>
                  </View>
                  <View style={styles.cellPink}>
                    <Text>Men</Text>
                  </View>
                  <View style={[styles.cellPink]}>
                    <Text style={ styles.fontBold}>Total Adults</Text>
                  </View>
                  <View style={[styles.cell ,styles.fontBold]}>
                    <Text>Grand Total (Project)</Text>
                  </View>
                </View>
                <View style={[styles.row]}>
                  <View style={styles.cell}>
                    <Text>AGE</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0-17 years</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>0-17 years</Text>
                  </View>
                  <View style={styles.cell}></View>
                  <View style={styles.cell}>
                    <Text>18-25 years</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>18-25 years</Text>
                  </View>
                  <View style={styles.cell}></View>
                  <View style={styles.cell}>
                    <Text>26+ years</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>26+ years</Text>
                  </View>
                  <View style={styles.cell}></View>
                  <View style={styles.cell}></View>
                </View>
                <View style={[styles.row]}>
                  <View style={styles.cell}></View>
                  <View style={styles.cell}>
                    <Text>{item?.ageGroups?.ageGroup_0_17?.boys ?? 0}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>{item?.ageGroups?.ageGroup_0_17?.girls ?? 0}</Text>
                  </View>
                  <View style={[styles.cell ,styles.fontBold]}>
                    <Text>{item?.ageGroups?.ageGroup_0_17?.total}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>
                      {item?.ageGroups?.ageGroup_18_25?.youthMale ?? 0}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>
                      {item?.ageGroups?.ageGroup_18_25?.youthFemale ?? 0}
                    </Text>
                  </View>
                  <View style={[styles.cell]}>
                    <Text style={ styles.fontBold}>{item?.ageGroups?.ageGroup_18_25?.total ?? 0}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>{item?.ageGroups?.ageGroup_26Plus?.men ?? 0}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>{item?.ageGroups?.ageGroup_26Plus?.women ?? 0}</Text>
                  </View>
                  <View style={[styles.cell]}>
                    <Text style={ styles.fontBold}>{item?.ageGroups?.ageGroup_26Plus?.total ?? 0}</Text>
                  </View>
                  <View style={[styles.cell]}>
                    <Text style={ styles.fontBold}>{item?.totalBeneficiaries ?? 0}</Text>
                  </View>
                </View>
              </View>
            );
          })}
        </Page>
      </Document>
    </>
  );
};

export default BeneficiariesReportPDF;
