import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { createRecord, getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import { truncateText } from "../../utils/util";
import ToolTip from "../tooltip/Tooltip";

const Notifications = () => {
  const dispatch = useDispatch();
  const { created } = useSelector((state) => state.records);
  const items = useSelector(
    (state) => state.records?.current?.headerNotification?.data?.items
  );

  useEffect(() => {
    if (created) getNotifications();
  }, [created]);

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/GetAll`,
        params: { OrderDir: ORDER_DIRECTION.DESC },
        appendCurrentReducerKey: "headerNotification",
      },
    });
  };

  const markAsRead = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/Notification/MarkAsRead?id=${id}`,
      },
    });
  };

  const notificationTitle = (title, id, type) => (
    <>
      {type === "Complaint" ? (
        <Link to={`/complaints/${id}`} className="text-primary cursor-pointer">
          {truncateText(title, 32)}
        </Link>
      ) : (
        truncateText(title, 32)
      )}
    </>
  );

  return (
    <div className="notif-wrapper">
      <div className="icon cursor-pointer">
        <OverlayTrigger
          trigger="click"
          onEnter={() => getNotifications()}
          rootClose
          placement="bottom"
          overlay={
            <Popover
              id={`popover-contained popover-positioned-bottom`}
              style={{ minWidth: 280 }}
            >
              <Popover.Body className="h-25">
                <p className="mb-1 border-bottom">Recent Notifications</p>
                {items &&
                  items.map(
                    ({ id, subject, isRead, entityType }, index) =>
                      index < 5 && (
                        <div
                          key={id}
                          className={`d-flex justify-content-between align-items-center border-bottom py-2 rounded m-0 mb-1 ${
                            !isRead && "font-weight-bold"
                          }`}
                          style={{ fontSize: 12 }}
                        >
                          <ToolTip label={subject}>
                            <label className="mb-0 pr-3">
                              {notificationTitle(subject, id, entityType)}
                            </label>
                          </ToolTip>

                          <>
                            {isRead ? (
                              <Icon.EnvelopeOpen />
                            ) : (
                              <ToolTip label="Mark as read">
                                <Icon.EnvelopeFill
                                  className="text-primary cursor-pointer"
                                  onClick={() => markAsRead(id)}
                                />
                              </ToolTip>
                            )}
                          </>
                        </div>
                      )
                  )}

                <Link to="/notifications" style={{ fontSize: 12 }}>
                  Show All
                </Link>
              </Popover.Body>
            </Popover>
          }
        >
          <div class="position-relative rounded-circle d-flex p-2">
            <Icon.Bell />
            <div class="position-absolute p-1 bg-danger dot border border-light rounded-circle"></div>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default Notifications;
