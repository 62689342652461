import ToolTip from "../../components/tooltip/Tooltip";
import { getFormatedDate } from "../../Utility";

const ActivityColumns = [
  {
    name: "Specific Objectives",
    selector: (row) => <ToolTip label={`${row?.specificObjective?.name}`}>{row?.specificObjective?.name}</ToolTip>,
  },
  {
    name: "Expected Result",
    selector: (row) => row?.expectedResult?.name,
  },
  {
    name: "Activity",
    selector: (row) => row?.activity?.name,
  },
  {
    name: "Sub Activity",
    selector: (row) => row?.name,
  },
  // , {
  //   name: 'Sub Activity',
  //   selector: row => (subActivityCol()),
  //   width: '250px'
  // }
  {
    name: "Start Date",
    selector: (row) => getFormatedDate(row?.startDate),
  },
  {
    name: "End Date",
    selector: (row) => getFormatedDate(row?.endDate)
  },
  {
    name: "Target",
    selector: (row) => row?.target
  },
  {
    name: "Target Type",
    selector: (row) => row?.targetType,
  },
  // , {
  //   name: 'Action',
  //   selector: row => (
  //     <><Icon.PencilSquare className="action-icons" onClick={openSubActivityModal} /> <Icon.Trash className="action-icons" /></>
  //   )
  // }
];
export default ActivityColumns;
