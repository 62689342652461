import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { setPageMeta } from "../../redux/reducers";
import { useDispatch } from "react-redux";
const UserDetailPage = () => {
  const { user } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const { userDetail = {} } = user;

  useEffect(() => {
    dispatch({ type: setPageMeta.type, payload: { title: "Account Details" } });
  }, []);

  return (
    <>
      <div className="mt-4">
        <h5>
          First Name: <span>{userDetail?.firstName}</span>
        </h5>
        <h5>
          last Name: <span>{userDetail?.lastName}</span>
        </h5>
        <h5>
          Email: <span>{userDetail?.email}</span>
        </h5>
      </div>
    </>
  );
};

export default UserDetailPage;
